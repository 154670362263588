import React from "react"

import Card from "../components/card"
import Layout from "../components/layout"
import Snippet from "../components/snippet"
import SnippetCard from "../components/snippetCard"

import layoutStyle from "../components/layout.module.css"
import style from "./partners.module.css"

import partnerLevelJson from "../../content/meta/partner-levels.json"
import partnerJson from "../../content/meta/partners.json"

const PartnersPage = () => (
	<Layout
		meta={{
			title: "Partners",
			description:
				"Become a partner of Accento and help us make it the best Java/JavaScript/Ops conference it can be.",
			keywords: "partners",
			path: "/partners",
		}}
		wide
	>
		<Snippet id="partners" />

		<div>
			<h2>Partnerships</h2>
			<h3>Companies</h3>
			<div className={style.cards}>
				{partnerJson.companies.map(partner => (
					<Card
						key={partner.imageSlug}
						size="medium"
						className={style.partner}
						imageSlug={partner.imageSlug}
						imageLink={partner.url}
						invertColors
					/>
				))}
				{partnerJson.media.map(partner => (
					<Card
						key={partner.imageSlug}
						size="medium"
						className={style.partner}
						imageSlug={partner.imageSlug}
						imageLink={partner.url}
						invertColors
					/>
				))}
			</div>

		{/*
			<h3>Communities</h3>
			<div className={style.cards}>
				{partnerJson.communities.map(partner => (
					<Card
						size="medium"
						className={style.partner}
						imageSlug={partner.imageSlug}
						imageLink={partner.url}
						invertColors
					/>
				))}
			</div>
		*/}

		</div>

		<div>
			<Snippet id="partners-companies-intro" />
			<div className={layoutStyle.cards}>
				{partnerLevelJson.levels.map(ticket => (
					<SnippetCard
						key={ticket.snippetId}
						size="medium"
						imageSlug={ticket.imageSlug}
						snippetId={ticket.snippetId}
						fillVertical
						className={style.level}
					/>
				))}
			</div>
			<Snippet id="partners-companies-outro" />
		</div>

		{/* <Snippet id="partners-communities" /> */}

		<Snippet id="partners-disy" />
	</Layout>
)

export default PartnersPage
