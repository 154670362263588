import React from "react"

import Card from "./card"
import Snippet from "./snippet"

const SnippetCard = ({ size, imageSlug, snippetId, invertColors, fillVertical, className }) => (
	<Card size={size} imageSlug={imageSlug} invertColors={invertColors} fillVertical={fillVertical} className={className}>
		<Snippet id={snippetId} />
	</Card>
)

export default SnippetCard
